<template>
  <div class="inline-flex items-center mb-5 cursor-pointer" @click="back">
    <icon-back />
    <span class="ml-2 text-xs text-invest-purple">Back</span>
  </div>
  <template v-if="loading">
    <skeleton-loader
      width="300px"
      height="150px"
      class-name="rounded-lg sm:w-4/12 mt-5"
    />

    <skeleton-loader
      width="10px"
      height="10px"
      class-name="rounded-lg sm:w-4/12 mt-5"
      v-for="i in 10"
      :key="i"
    />
  </template>
  <template v-else>
    <div class="w-full sm:w-4/12">
      <div class="mb-5 text-2xl font-bold text-invest-primary">
        {{ investment.name }}
      </div>

      <investment class-name="w-98">
        <div class="flex justify-between mt-2 text-xs text-invest-darkgray">
          <p>Balance</p>
          <span :class="`text-${statusInvestment.bgColor}`">{{
            statusInvestment.investStatus
          }}</span>
        </div>
        <span class="mt-5 text-2xl font-bold text-invest-primary">
          {{ $filters.formatAmount(investmentAmount) }}
        </span>
      </investment>
      <div class="grid grid-cols-2 gap-3 mt-5">
        <button
          class="text-xs btn btn-dark"
          :class="canReInvestOrWithdraw ? 'col-span-1' : 'col-span-2'"
          :disabled="setLoading"
          type="button"
          @click="$refs.withdrawInfo.open()"
          v-if="canLiquidate"
        >
          <loader v-if="setLoading" />
          <span v-else>Liquidate</span>
        </button>
        <button
          class="text-xs btn btn-primary col-span-1"
          :class="canReInvestOrWithdraw ? 'col-span-2' : 'col-span-1'"
          type="button"
          @click="withdraw"
          v-if="canReInvestOrWithdraw"
        >
          Withdraw
        </button>
      </div>

      <div class="mt-12 text-sm font-medium text-invest-primary">
        Investment Details
      </div>

      <div class="investment-details">
        <p>Earnings so far</p>
        <span class="text-invest-primary">
          {{ $filters.formatAmount(investment.earnings) }}
        </span>
      </div>

      <div class="investment-details">
        <p>Principal amount</p>
        <span class="text-invest-primary">
          {{ $filters.formatAmount(investment.amount) }}
        </span>
      </div>

      <div class="investment-details">
        <p>Interest rate</p>
        <span class="text-invest-primary">
          {{ investment.percentage }}% annually
        </span>
      </div>

      <div class="investment-details" v-if="investmentClosingAt">
        <p>Maturity date</p>
        <span class="text-invest-primary">
          {{ investmentClosingAt }}
        </span>
      </div>
      <div class="investment-details">
        <p>Start date</p>
        <span class="text-invest-primary">
          {{ startDate }}
        </span>
      </div>
    </div>
  </template>

  <modal ref="withdrawInfo" class-name="w-11/12 pt-10 sm:w-97">
    <div class="flex justify-center mt-3">
      <withdraw-icon />
    </div>
    <div class="mt-8 text-center">
      <h3 class="text-2xl font-bold tracking-base">Withdraw Investment?</h3>
      <p class="mt-2 mb-5 text-xs leading-loose text-invest-dark">
        Withdrawing funds before maturity date would attract a 30% penalty fee
        on the interest earned so far.
      </p>
    </div>
    <div class="grid h-16 grid-cols-1 place-content-end">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="setLoading"
        @click="liquidate"
      >
        Yes, Confirm Withdraw
      </button>
    </div>
  </modal>

  <alert-modal ref="successModal" :alert-type="error ? 'error' : 'success'">
    <template #title>
      {{ error || 'Investment Liquidated Successfully' }}
    </template>
  </alert-modal>

  <withdraw-investment
    :amount="investmentAmount"
    :investment="investment"
    @onSuccess="getInvestmentDetails"
    ref="withdrawModal"
    :key="componentKey"
  />

  <re-invest ref="reInvestModal" :investment="investment" :key="componentKey" />
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
  computed,
  nextTick,
} from 'vue';
import { useRouter } from 'vue-router';
import {
  getInvestment,
  getInvestmentTransaction,
  liquidateInvestment,
} from '@/modules/requests';
import { InvestmentProp, TransactionProp } from '@/types/Investment';
import investmentModule from '@/modules/investment/investmentModule';
import { format } from 'date-fns';
import { AxiosResponse, AxiosError } from 'axios';

export default defineComponent({
  components: {
    WithdrawInvestment: defineAsyncComponent(
      () => import('@/components/investment/withdraw-investment/Index.vue'),
    ),
    ReInvest: defineAsyncComponent(
      () => import('@/components/investment/reinvest/Index.vue'),
    ),
  },

  setup: () => {
    const { push, currentRoute } = useRouter();

    const withdrawModal = ref<any>(null);

    const reInvestModal = ref<any>(null);

    const successModal = ref<any>(null);

    const investment = ref<any>({} as InvestmentProp);

    const loading = ref<boolean>(false);

    const setLoading = ref<boolean>(false);

    const withdrawInfo = ref<any>(null);

    const componentKey = ref<number>(0);

    const transactions = ref<TransactionProp[]>([]);

    const error = ref<any>(null);

    const amount = computed(() => investment.value.amount);

    const canReInvestOrWithdraw = computed(() =>
      ['closed', 'liquidated'].includes(investment.value?.status),
    );

    const canLiquidate = computed(() =>
      ['running'].includes(investment.value?.status),
    );

    const investmentAmount = computed(() => {
      return investment.value?.liquidated_at
        ? investment.value?.liquidateable
        : investment.value?.earnings === 0
        ? investment.value?.amount
        : investment.value?.earnings;
    });

    const investmentClosingAt = computed(
      () =>
        investment.value?.closing_at &&
        format(new Date(investment.value?.closing_at), 'do MMMM, yyyy'),
    );

    const statusInvestment = computed(() => {
      const { investmentStatus } = investmentModule();
      const { status, bgColor } = investmentStatus(investment.value);
      return { investStatus: status, bgColor };
    });

    const startDate = computed(() =>
      format(new Date(investment.value?.active_at), 'do MMMM, yyyy'),
    );

    watch(
      () => investment.value,
      () => getTransactions(),
    );

    const getTransactions = async (): Promise<any> => {
      await getInvestmentTransaction(1, investment.value?.id)
        .then((response: AxiosResponse) => {
          transactions.value = response?.data.data;
        })
        .catch((error: AxiosError) => console.log(error));
    };

    const getInvestmentDetails = async (): Promise<any> => {
      loading.value = true;
      await getInvestment(currentRoute.value?.params.id as string)
        .then((response: AxiosResponse) => {
          investment.value = response.data as InvestmentProp;
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (loading.value = false));
    };

    const withdraw = () => {
      componentKey.value++;
      nextTick(() => withdrawModal.value?.open());
    };

    const liquidate = async (): Promise<any> => {
      setLoading.value = true;
      withdrawInfo.value.close();
      await liquidateInvestment(investment.value?.id)
        .then(async (): Promise<any> => {
          successModal.value?.open();
          await getInvestmentDetails();
        })
        .catch((err: AxiosError) => {
          error.value = err?.response?.data?.message;
          successModal.value.open();
        })
        .finally(() => (setLoading.value = false));
    };

    const reInvest = () => {
      componentKey.value++;
      nextTick(() => reInvestModal.value?.open());
    };

    const back = () => push({ name: 'Investment' });

    onBeforeMount(getInvestmentDetails);

    return {
      back,
      withdraw,
      withdrawModal,
      investment,
      loading,
      amount,
      startDate,
      canReInvestOrWithdraw,
      investmentClosingAt,
      getInvestmentDetails,
      statusInvestment,
      canLiquidate,
      componentKey,
      withdrawInfo,
      reInvest,
      reInvestModal,
      successModal,
      liquidate,
      setLoading,
      error,
      investmentAmount,
    };
  },
});
</script>
