
import {
  defineAsyncComponent,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
  computed,
  nextTick,
} from 'vue';
import { useRouter } from 'vue-router';
import {
  getInvestment,
  getInvestmentTransaction,
  liquidateInvestment,
} from '@/modules/requests';
import { InvestmentProp, TransactionProp } from '@/types/Investment';
import investmentModule from '@/modules/investment/investmentModule';
import { format } from 'date-fns';
import { AxiosResponse, AxiosError } from 'axios';

export default defineComponent({
  components: {
    WithdrawInvestment: defineAsyncComponent(
      () => import('@/components/investment/withdraw-investment/Index.vue'),
    ),
    ReInvest: defineAsyncComponent(
      () => import('@/components/investment/reinvest/Index.vue'),
    ),
  },

  setup: () => {
    const { push, currentRoute } = useRouter();

    const withdrawModal = ref<any>(null);

    const reInvestModal = ref<any>(null);

    const successModal = ref<any>(null);

    const investment = ref<any>({} as InvestmentProp);

    const loading = ref<boolean>(false);

    const setLoading = ref<boolean>(false);

    const withdrawInfo = ref<any>(null);

    const componentKey = ref<number>(0);

    const transactions = ref<TransactionProp[]>([]);

    const error = ref<any>(null);

    const amount = computed(() => investment.value.amount);

    const canReInvestOrWithdraw = computed(() =>
      ['closed', 'liquidated'].includes(investment.value?.status),
    );

    const canLiquidate = computed(() =>
      ['running'].includes(investment.value?.status),
    );

    const investmentAmount = computed(() => {
      return investment.value?.liquidated_at
        ? investment.value?.liquidateable
        : investment.value?.earnings === 0
        ? investment.value?.amount
        : investment.value?.earnings;
    });

    const investmentClosingAt = computed(
      () =>
        investment.value?.closing_at &&
        format(new Date(investment.value?.closing_at), 'do MMMM, yyyy'),
    );

    const statusInvestment = computed(() => {
      const { investmentStatus } = investmentModule();
      const { status, bgColor } = investmentStatus(investment.value);
      return { investStatus: status, bgColor };
    });

    const startDate = computed(() =>
      format(new Date(investment.value?.active_at), 'do MMMM, yyyy'),
    );

    watch(
      () => investment.value,
      () => getTransactions(),
    );

    const getTransactions = async (): Promise<any> => {
      await getInvestmentTransaction(1, investment.value?.id)
        .then((response: AxiosResponse) => {
          transactions.value = response?.data.data;
        })
        .catch((error: AxiosError) => console.log(error));
    };

    const getInvestmentDetails = async (): Promise<any> => {
      loading.value = true;
      await getInvestment(currentRoute.value?.params.id as string)
        .then((response: AxiosResponse) => {
          investment.value = response.data as InvestmentProp;
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (loading.value = false));
    };

    const withdraw = () => {
      componentKey.value++;
      nextTick(() => withdrawModal.value?.open());
    };

    const liquidate = async (): Promise<any> => {
      setLoading.value = true;
      withdrawInfo.value.close();
      await liquidateInvestment(investment.value?.id)
        .then(async (): Promise<any> => {
          successModal.value?.open();
          await getInvestmentDetails();
        })
        .catch((err: AxiosError) => {
          error.value = err?.response?.data?.message;
          successModal.value.open();
        })
        .finally(() => (setLoading.value = false));
    };

    const reInvest = () => {
      componentKey.value++;
      nextTick(() => reInvestModal.value?.open());
    };

    const back = () => push({ name: 'Investment' });

    onBeforeMount(getInvestmentDetails);

    return {
      back,
      withdraw,
      withdrawModal,
      investment,
      loading,
      amount,
      startDate,
      canReInvestOrWithdraw,
      investmentClosingAt,
      getInvestmentDetails,
      statusInvestment,
      canLiquidate,
      componentKey,
      withdrawInfo,
      reInvest,
      reInvestModal,
      successModal,
      liquidate,
      setLoading,
      error,
      investmentAmount,
    };
  },
});
